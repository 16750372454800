import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import BusinessCheckingOverview from "../../../components/checking-overview/business-checking-overview";
import SEO from "../../../components/seo/seo";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import ContactInfo from "../../../components/contact-info/contact-info";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const BusinessCheckingAccount = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/business-banking/business-checking-account/hero-business-checking-02-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/business-banking/business-checking-account/hero-business-checking-02-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/business-banking/business-checking-account/hero-business-checking-02-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/business-banking/business-checking-account/hero-business-checking-02-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/business-banking/business-checking-account/hero-business-checking-02-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/business-banking/business-checking-account/hero-business-checking-02-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/business-banking/business-checking-account/hero-business-checking-02-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const cardsData = useCardBlogsData([
    "/blog/small-business/what-is-needed-to-open-business-bank-account",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business",
    "/blog/small-business/how-to-grow-small-business"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const heroChevronData = {
    id: "business-checking-account-hero",
    ...getHeroImgVariables(imgData, ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"]),
    altText: "Businesswoman in office using phone",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Our Philosophy: Keep Checking Simple, and Dream Big",
            class: "text-white"
          }
        },
        {
          id: 2,
          button: {
            id: "small-business-hero-cta",
            text: "Open an Account",
            url: "#business-checking-overview-section",
            class: "btn-white",
            type: "anchor-link",
            icon: {
              position: "right",
              lib: "fas",
              name: "arrow-down",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Business Checking"
    }
  ];

  const SEOData = {
    title: "Business Checking Accounts",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Business Checking Accounts"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Business checking from WaFd Bank gives you the flexibility to work with your business, no matter how big or small. Learn more and visit a branch."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-checking-account"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-small-business-checking.jpg"
      }
    ]
  };

  const articleCardsData = {
    sectionClass: "bg-white",
    hasGrowEffect: true,
    cards: featuredCardsData.map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  const iconListData = [
    {
      img: "../../../images/icons/pay-bills-icon.svg",
      text: "Online Banking with Bill Payment"
    },
    {
      img: "../../../images/icons/mobile-deposit-icon.svg",
      text: "Mobile Photo Deposit & Remote Deposit Capture"
    },
    {
      img: "../../../images/icons/sweep-accounts-icon.svg",
      text: "Automatic Sweep Accounts"
    },
    {
      img: "../../../images/icons/eStatements-icon.svg",
      text: "eStatements"
    },
    {
      img: "../../../images/icons/debit-card-icon.svg",
      text: "Visa Debit Card"
    },
    {
      img: "../../../images/icons/eWire-icon.svg",
      text: "eWire Transfer Service"
    },
    {
      img: "../../../images/icons/ach-payments-icon.svg",
      text: "Electronic ACH Payments"
    },
    {
      img: "../../../images/icons/credit-card-processing-icon.svg",
      text: "Credit Card Processing"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="business-checking-intro-section" className="container pb-0">
        <h1>Business Checking</h1>
        <h2 className="text-success">Only $100 initial deposit to open.*</h2>
        <h3>
          At WaFd Bank, we offer several hard-working checking account options to make managing your business easier.
        </h3>
      </section>
      <BusinessCheckingOverview />
      <section className="container pt-0">
        <h2>Everything You Need in a Business Checking Account</h2>
        <div className="row mt-4">
          <div className="col-md-6">
            {iconListData.map((data, index) => (
              <div className="row mb-3" key={index} id={`checking-list-item-${index + 1}`}>
                <div className="col-2 col-sm-1 col-md-2 col-lg-1">
                  <img src={data.img} alt="" />
                </div>
                <div className="col-10 col-lg-11 font-weight-semi-bold">{data.text}</div>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../../images/thumbnail-business-checking-011724.jpg"
              alt="Business owner using phone to make a mobile photo deposit."
              placeholder="blurred"
              quality="100"
            />
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <h2>Upgrade your account with these options:</h2>
          <div className="row">
            <div className="col-md-6">
              <h4>
                <span className="font-weight-bold text-success">Remote Deposit Capture:</span> Submit your bank deposit
                using a simple desktop scanning device.
              </h4>
              <ul id="remote-deposit-capture-ul">
                <li id="remote-deposit-capture-li-1">Save time, no need to drive your deposits to the bank</li>
                <li id="remote-deposit-capture-li-2">Receive credit faster</li>
                <li id="remote-deposit-capture-li-3">Access images of deposited items</li>
              </ul>
              <h4>
                <span className="font-weight-bold text-success">eWires:</span> Send Domestic and International wires
                quickly.
              </h4>
            </div>
            <div className="col-md-6">
              <h4>
                <span className="font-weight-bold text-success">eACH:</span> A secure and fast way to send and receive
                payments.
              </h4>
              <ul id="e-ach-ul">
                <li id="e-ach-li-1">Vendor Payments</li>
                <li id="e-ach-li-2">Payroll</li>
                <li id="e-ach-li-3">Payment Collection</li>
              </ul>
              <h4>
                <span className="font-weight-bold text-success">Additional Options:</span> More ways to upgrade your
                account.
              </h4>
              <ul id="additional-options-ul">
                <li id="additional-options-li-1">Positive Pay Fraud Prevention</li>
                <li id="additional-options-li-2">Sweep Accounts</li>
                <li id="additional-options-li-3">Zero Balance Accounts</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <StretchedLinkCards {...articleCardsData} />
      <ContactInfo type="business" />
    </SecondaryLanding>
  );
};

export default BusinessCheckingAccount;
